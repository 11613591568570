import { Ability, AbilityBuilder } from '@casl/ability';

import { User } from '../api/responses/signin.response';
import { Routes } from '../enum/routes.enum';
import { Roles } from './roles';

export default function defineAbilityFor(user?: User) {
  const { can, cannot, rules } = new AbilityBuilder();
  const {
    Analytics,
    ObjectAnalytic,
    ContractorAnalytics,
    ContractorFinancialAnalytics,
    Objects,
    Prescriptions,
    Object,
    Contractors,
    ContractorProfile,
    AddObject,
    EditObject,
    ContractorForm,
    ContractorsEdit,
    AddPrescriptionAcceptance,
    AddPrescriptionOperational,
    EditPrescriptionAcceptance,
    EditPrescriptionOperational,
    Login,
    Profile,
    Remark,
    Handbook,
    PrescriptionsAnalytics,
    ObjectGanttChart,
    AddGPR,
    GPR,
  } = Routes;
  const analyticsScreens = [
    Analytics,
    ObjectAnalytic,
    ContractorAnalytics,
    ContractorFinancialAnalytics,
    Handbook,
  ];
  const ganttChartScreens = [
    PrescriptionsAnalytics,
    ObjectGanttChart,
  ];
  const role = user?.role;

  if (role?.id === Roles.administrator) {
    can(
      ['create', 'reset password', 'delete', 'edit', 'change'],
      [
        'user',
        'object',
        'contractor',
        'prescription',
        'prescriptionStatus',
        'contractorFinancingSchedule',
        'contractorDocuments',
        'gpr',
        'gprItemAccess',
        'objectAccess',
      ],
    );
    can('view', 'all');
    can('viewOther', [
      Prescriptions,
      Contractors,
      ContractorProfile,
      ContractorsEdit,
    ]);
  }

  if (role?.id === Roles.head) {
    can('view', [
      ...analyticsScreens,
      Objects,
      Object,
      Prescriptions,
      Contractors,
      ContractorProfile,
      Remark,
      ...ganttChartScreens,
      GPR,
    ]);
    can('viewOther', [Prescriptions, Contractors, ContractorProfile]);
  }

  if (role?.id === Roles.engineerPTO) {
    can('view', [
      Objects,
      Object,
      AddObject,
      EditObject,
      Prescriptions,
      Remark,
      ...analyticsScreens,
      Contractors,
      ContractorProfile,
      ContractorForm,
      ContractorsEdit,
      AddGPR,
      GPR,
    ]);
    can(
      ['create', 'edit', 'change'],
      ['object', 'contractor', 'contractorFinancingSchedule', 'contractorDocuments', 'gpr', 'gprItemAccess'],
    );
    can('viewOther', [
      Prescriptions,
      Contractors,
      ContractorProfile,
      ContractorsEdit,
    ]);
  }

  if (role?.id === Roles.constructionControlEngineer) {
    can('view', [
      Objects,
      Object,
      Prescriptions,
      AddPrescriptionAcceptance,
      AddPrescriptionOperational,
      EditPrescriptionAcceptance,
      EditPrescriptionOperational,
      ...analyticsScreens,
      Contractors,
      ContractorProfile,
      Remark,
      ...ganttChartScreens,
      AddGPR,
      GPR,
    ]);
    can('create', 'prescription');
    can('change', 'prescriptionStatus');
    can('viewOther', [Prescriptions, Contractors, ContractorProfile]);
  }

  if (role?.id === Roles.contractorOrganization) {
    can('view', [
      Objects,
      Object,
      Prescriptions,
      Remark,
      ContractorProfile,
      ContractorsEdit,
      GPR,
    ]);
    cannot('viewOther', [
      Prescriptions,
      Contractors,
      ContractorProfile,
      ContractorsEdit,
    ]);
    can('edit', 'contractorFinancingSchedule');
    can('edit', 'contractorDocuments');
    can('change', 'prescriptionStatusRedToYellow');
  }

  if (role?.id === Roles.foreman) {
    can('view', [
      Objects,
      Object,
      Prescriptions,
      Remark,
      ...analyticsScreens,
      Contractors,
      ContractorProfile,
      ContractorForm,
      GPR,
    ]);
    can('viewOther', [
      Prescriptions,
      Contractors,
      ContractorProfile,
    ]);
  }

  can('view', [Login, Profile]);

  return new Ability(rules as any);
}
