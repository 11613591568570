import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { SignIn } from '../types/signin.interface';
import { TokenPayload } from '../types/token-payload.interface';
import { ApiRoutes } from './api-routes.enum';
import { accessTokenKey, userData } from './constants';
import { SignInResponse } from './responses/signin.response';

export const api = axios.create({
//  baseURL: 'http://localhost/smartcontrol/public/api/v1'
//  baseURL: 'https://back.smartcontrol.it-meteor.ru/api/v1',
//  baseURL: 'https://back.smartcontrol.ru.com/api/v1',
//  baseURL: 'https://back.sc-sd.ru/api/v1',
//  baseURL: 'https://back.sc-sd.online/api/v1',
  baseURL: 'https://back.hm.sc-soft.pro/api/v1',
//  baseURL: 'https://back.sc-rascvetay.ru/api/v1',
//  baseURL: 'https://back.test.sc-soft.pro/api/v1'
});

export const Auth = {
  get accessToken() {
    return localStorage.getItem(accessTokenKey);
  },
  set accessToken(value) {
    if (value) {
      localStorage.setItem(accessTokenKey, value);
    } else {
      localStorage.removeItem(accessTokenKey);
    }
  },

  get userData() {
    return JSON.parse(localStorage.getItem(userData) || '{}');
  },
  set userData(value) {
    if (value) {
      localStorage.setItem(userData, value);
    } else {
      localStorage.removeItem(userData);
    }
  },

  get authorized() {
    return this.checkToken();
  },

  async signIn(signIn: SignIn) {
    const res = await api.post<SignInResponse>(ApiRoutes.auth, signIn);
    Auth.accessToken = res.data.access_token;
    Auth.userData = JSON.stringify(res.data.user);
    return res;
  },

  async signOut() {
    Auth.accessToken = null;
    Auth.userData = null;
    localStorage.clear();
  },

  checkToken() {
    if (Auth.accessToken) {
      const { exp } = jwtDecode<TokenPayload>(Auth.accessToken); // seconds
      const realExp = exp * 1000; // ms
      if (Date.now() < realExp) {
        return true;
      }
      this.signOut();
    }
    return false;
  },
};

api.interceptors.request.use(req => {
  req.headers.Authorization = `Bearer ${Auth.accessToken}`;
  return req;
});

api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    if(Auth.authorized){
      Auth.signOut();
      window.location.reload();
    }
  }
  return error;
});
